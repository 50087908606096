<template>
  <c-box 
    w="full"
  >
    <BreadcrumbPath
      px="0"
      pb="20px"
      :paths="[
        { label: 'Manajemen Klien', href: { name: 'admin.clients' } },
        { label: 'Detail Klien', href: { name: 'admin.clients-detail', query: $route.query } },
        { label: 'Detail Program', href: { name: 'admin.clients-programs', query: $route.query } },
        { label: 'Pilih Ahli Gizi', isCurrent: true },
      ]"
    />
    <c-box
      position="relative"
      mx="auto"
      :mb="['0', '16px']"
      :p="['1rem', '30px']"
      border="1px solid #f2f2f2"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      width="100%"
    >
      <c-box
        mx="auto"
        :px="['0', '40px']"
      >
        <c-text
          font-size="28px"
          font-weight="700"
          line-height="150%"
          text-align="center"
        >
          Pilih Ahli Gizi
        </c-text>

        <c-box mt="30px">
          <c-text
            font-size="20px"
            color="primary.400"
            font-weight="700"
            line-height="150%"
          >
            Ahli gizi tersedia
          </c-text>
          <hr>
          
          <c-box
            :w="['100%', 'auto']"
            :margin-top="['0', '30px']"
            :margin-bottom="['0', '30px']"
            d="flex"
            justify-content="center"
            align-items="center"
            gap="8px"
          >
            <c-box
              pos="relative"
              w="100%"
            >
              <c-image
                :src="require('@/assets/ic-search.svg')"
                :h="['14.58px','21.86px']"
                :w="['14.58px','21.86px']"
                alt="icon"
                pos="absolute"
                top="50%"
                left="15px"
                transform="translateY(-50%)"
                z-index="2"
              />
              <c-input
                id="searchId"
                v-model="search"
                type="text"
                w="100%"
                placeholder="Search"
                :border-radius="['1000px', '20px']"
                padding-left="50px"
                pos="relative"
                z-index="1"
                font-family="Roboto"
                :font-size="['14px', '18px']"
                :font-weight="['400', '500']"
                :h="['38px', '62px']"
                @keyup="onSearch"
              />
            </c-box>
            <ModalFilterNutritionist
              :options="listFilterSpecialist"
              :data="filterSpecialist"
              @change="handleChangeFilterSpecialist"
            />
          </c-box>

          <CBox
            v-if="filterSpecialist.length > 0"
            v-dragscroll
            py="3px"
            :px="['16px', '0px']"
            overflow="hidden"
            :h="['unset', 'calc(3px + 3px + 40px)']"
            position="relative"
            mb="10px"
          >
            <CList
              ref="list"
              d="flex"
              gap="4px"
              :position="['unset','absolute']"
            >
              <CListItem
                v-for="(filter, idx) in filterSpecialist"
                :key="filter"
                style="list-style: none;"
              >
                <c-button
                  variant-color="primary"
                  variant="outline"
                  bg="#C7F9E3"
                  rounded="1000px"
                  :h="['34px', '40px']"
                  @click="handleDeleteFilterSpecialistByIndex(idx)"
                >
                  {{ filter }}
                </c-button>
              </CListItem>
            </CList>
          </CBox>

          <c-box
            v-if="isLoadingInit"
          >
            <SkeletonCardNutritionist
              v-for="index in 2"
              :key="index"
              v-chakra="{
                mb: '12px',
              }"
              type="admin"
            />
          </c-box>

          <CardNutritionist
            v-if="!isLoadingInit && hasNutritionist"
            type="admin"
            :nutritionists="listNutritionists"
            :is-disabled-choose-nutritionist="false"
            :universities="universities"
            button-submit-text="Ganti Ahli Gizi"
            button-submit-text-md="Ganti Ahli Gizi"
            :used-nutritionist="usedNutritionist"
            @on-choose-nutritionist="onChooseNutritionist"
            @on-profile-nutritionist="onOpenDetailNutritionist"
          />
          
          <c-flex
            v-if="!isEmpty && !isLoadingInit"
            justify="space-between"
            py="28px"
            px="40px"
          >
            <c-flex align="center">
              <c-text
                color="gray.900"
                font-size="14px"
                line-height="21px"
              >
                Show:
              </c-text>
              <c-select
                v-model="perPage"
                min-w="120px"
                border-radius="6px"
                font-size="14px"
                ml="13px"
                size="md"
                @change="onSearch"
              >
                <option value="5">
                  5 Rows
                </option>
                <option value="10">
                  10 Rows
                </option>
                <option value="15">
                  15 Rows
                </option>
                <option value="20">
                  20 Rows
                </option>
              </c-select>
            </c-flex>

            <c-flex
              justify-content="flex-end"
              w="100%"
            >
              <c-flex
                justify-content="center"
                align="center"
              >
                <c-button
                  p="0"
                  mr="16px"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  variant="outline"
                  border-radius="30px"
                  background-color="transparent"
                  :is-disabled="currentPage === 1"
                  @click="prevPage"
                >
                  <c-icon
                    name="chevron-left"
                    size="24px"
                  />
                </c-button>

                <c-button
                  v-for="page_ in pages"
                  :key="page_"
                  v-chakra="{
                    ':hover': {
                      bg: 'rgba(0, 140, 129, 0.3)',
                      color: 'primary.400',
                    },
                  }"
                  p="0"
                  m="0"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  mx="4px"
                  border-radius="30px"
                  background-color="rgba(0, 140, 129, 0.3)"
                  color="primary.400"
                  :bg="
                    page_ === currentPage
                      ? ['rgba(0, 140, 129, 0.3)']
                      : ['transparent']
                  "
                  as="router-link"
                  :to="{ name: $route.name, query: { ...$route.query, page: page_ } }"
                >
                  {{ page_ }}
                </c-button>

                <c-button
                  p="0"
                  ml="16px"
                  w="32px"
                  h="32px"
                  min-w="32px"
                  variant="outline"
                  border-radius="30px"
                  background-color="transparent"
                  :is-disabled="pages.length === page"
                  @click="nextPage"
                >
                  <c-icon
                    name="chevron-right"
                    size="24px"
                  />
                </c-button>
              </c-flex>
            </c-flex>
          </c-flex>
        </c-box>
      </c-box>
    </c-box>
    <ModalConfirm
      :title="confirm.title"
      :sub-title="confirm.subTitle"
      max-width="500px"
      :image="require('@/assets/icon-confirm-update.svg')"
      :is-open="isConfirm"
      :is-loading="isLoadingNutritionists"
      @close="onCloseConfirm"
      @submit="() => {
        onSubmitNutritionist(selectedNutritionists)
      }"
    />

    <ModalNutritionists
      :is-show-skeleton="isShowSkeletonModalNutritionist"
      :is-open="isOpenModal"
      :str-number="nutritionistModal.str"
      :specializations="nutritionistModal.specialization"
      :service-hour="nutritionistModal.serviceHour"
      :rating="nutritionistModal.rating"
      :problem-handles="nutritionistModal.problemHandled"
      :photo-url="nutritionistModal.photoUrl"
      :languages="nutritionistModal.languages ? [
        nutritionistModal.languages
      ] : []"
      :client-age-handles="nutritionistModal.clientAgeHandled ? [
        nutritionistModal.clientAgeHandled,
      ] : []"
      :fullname="getFullnameAndTitle(`${nutritionistModal.firstName || ''} ${nutritionistModal.lastName || ''}`.trim(), nutritionistModal.education)"
      :educations="educationsParser(nutritionistModal.education)"
      :work-experiences="workExperiencesParser(nutritionistModal.workExperiences)"
      :chat-active-hours="chatActiveHoursParser(nutritionistModal.activeHour)"
      :service-times="serviceTimesParser(nutritionistModal.serviceTime)"
      with-button-choose-nutritionist
      :is-disabled-button-choose-nutritionist="nutritionistModal.remainingQuota <= 0 || usedNutritionist === nutritionistModal.id"
      :button-submit-text="usedNutritionist === nutritionistModal.id ? 'Dipilih' : 'Ganti Ahli Gizi'"
      :used-nutritionist="usedNutritionist"
      @on-close="onCloseDetailNutritionist"
      @on-choose-nutritionist="() => onChooseNutritionist(nutritionistModal.id)"
    />
  </c-box>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import CardNutritionist from '@/views/client/nutritionists/_widgets/card-nutritionist.vue'
import ModalFilterNutritionist from '@/views/client/nutritionists/_widgets/modal-filter-nutritionist.vue'
import ModalConfirm from '@/components/widgets/modal-confirm'
import ModalNutritionists from '@/components/widgets/modal-nutritionists'
import modalNutritionistsMixin from '@/components/widgets/modal-nutritionists-mixin'
import { dragscroll } from 'vue-dragscroll'
import SkeletonCardNutritionist from '@/components/skeletons/skeleton-card-nutritionist.vue'

export default {
  name: 'AdminClientNutris',
  components: { 
    BreadcrumbPath,
    CardNutritionist,
    ModalFilterNutritionist,
    ModalNutritionists,
    ModalConfirm,
    SkeletonCardNutritionist,
  },
  directives: {
    dragscroll,
  },
  mixins: [
    modalNutritionistsMixin,
  ],
  data() {
    return {
      page: 1,
      perPage: '10',
      search: this.$route.query?.query ?? '',
      specialization: '',
      listFilterSpecialist: [],
      filterSpecialist: this.$route.query?.specialization ? JSON.parse(this.$route.query?.specialization) : [],
      timer: null,
      totalData: 1,
      isMobileFilter: false,
      resListNutritionist: {},
      isOpenModal: false,
      selectedNutritionists: '',
      isConfirm: false,
      isLoadingNutritionists: false,
      isLoadingInit: true,
      nutritionistModal: {},
      isShowSkeletonModalNutritionist: true,
      confirm: {
        title: 'Apakah kamu yakin ingin mengganti ahli gizi?',
        subTitle: '',
      },
      programDetail: null,
    }
  },
  computed: {
    ...mapGetters({
      axios: 'axios',
      universities: 'general/universities',
    }),
    listNutritionists() {
      return this.resListNutritionist || []
    },
    hasNutritionist() {
      return this.listNutritionists.length > 0
    },
    canChooseType() {
      return this.resListNutritionist?.canChoose
    },
    isEmpty() {
      return !this.hasNutritionist
    },
    currentPage() {
      return parseInt(this.$route.query.page ?? 1, 10)
    },
    pages() {
      let total = this.totalData ?? 1
      let perPage = this.perPage ?? 10
      let length = Math.ceil(total / perPage)
      return Array.from({ length }, (_, idx) => idx + 1)
    },
    programId() {
      return this.$route.params.programId
    },
    usedNutritionist() {
      return this.programDetail?.nutritionist?.id
    },
  },
  watch: {
    selectedNutritionists: {
      immediate: true,
      handler(val) {
        if (val) {
          this.isShowSkeletonModalNutritionist = true
          this.getNutritionist(val).then((res) => {
            this.nutritionistModal = res
            this.isShowSkeletonModalNutritionist = false
          })
        }
      },
    },
    filterSpecialist() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        const specialization = this.filterSpecialist.length > 0 ? JSON.stringify(this.filterSpecialist) : undefined

        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page: 1,
            specialization,
          },
        }) }, 800)
    },
    $route: {
      immediate: true,
      handler(route) {
        this.isLoadingInit = true
        this.fetchListNutritionist(route.query)
          .finally(() => this.isLoadingInit = false)
      },
    },
  },
  async mounted() {
    this.init()
    this.loadUniversities()
  },
  methods: {
    ...mapActions({
      loadUniversities: 'general/loadUniversities',
      getNutritionist: 'admNutritionists/detailNutritionists',
      getNutritionistDetailProgram: 'clients/getNutritionistDetailProgram',
    }),
    async init() {
      try {
        const res = await this.getNutritionistDetailProgram(this.programId)
        this.programDetail = res.data
      } catch (err) {
        this.$toast({
          title: 'Failed',
          description: err?.data?.message ? err?.data?.message : (err?.response?.data?.message ?? 'Ops! Something when wrong.'),
          status: 'error',
          duration: 5000,
          position: 'bottom-right',
          variant: 'subtle',
        })
        this.$router.push({
          name: 'admin.clients', 
        })
      }
    },
    onSearch() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            page: 1,
            query: this.search || undefined,
          },
        })
      }, 800)
    },
    prevPage() {
      this.page -= 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      })
    },
    nextPage() {
      this.page += 1
      this.$router.push({
        name: this.$route.name,
        query: { ...this.$route.query, page: this.page },
      })
    },
    onChangeSpecialization(e) {
      this.specialization = e
      this.isMobileFilter = false
      if (e === 'Semua spesialisasi') {
        this.specialization = ''
      }
      this.$router.replace({
        name: this.$route.name,
        query: {
          page: 1,
          query: this.search,
          specialization: this.specialization,
        },
      })
    },
    async fetchListNutritionist({ page = 1, query = null, specialization = null } = {}) {
      const params = {
        perpage: this.perPage,
        page,
        ...(specialization && { specialization }),
        ...(query && { q: query }),
      }
      try {
        const res = await this.axios.get('/v1/admin/nutritionist', { params })
        this.resListNutritionist = res.data?.data
        this.totalData = res.data?.meta?.total
        this.listFilterSpecialist = res.data?.meta?.filter.filter((it) => it)
      } catch (e) {
        console.error(e)
      }
    },
    onCloseConfirm() {
      this.isConfirm = false
      if (!this.isOpenModal) {
        this.selectedNutritionists = ''
      }
    },
    onOpenDetailNutritionist(id) {
      this.isOpenModal = true
      this.selectedNutritionists = id
    },
    onCloseDetailNutritionist() {
      this.isOpenModal = false
    },
    onChooseNutritionist(id) {
      const ahliGizi = this.listNutritionists.find((it) => it?.id == id)
      this.confirm.subTitle = `Apakah kamu yakin ingin mengganti ahli gizi klien ${this.programDetail?.user?.fullName ?? '-'} dari ${this.programDetail?.nutritionist?.fullName ?? '-'} menjadi ${ahliGizi?.firstName ?? '-'} ${ahliGizi?.lastName ?? ''}`
      this.onCloseDetailNutritionist()
      if (this.canChooseType === 'cannot') {
        this.isError = true
        return
      }

      this.selectedNutritionists = id
      this.isConfirm = true
    },
    handleDeleteFilterSpecialistByIndex(index) {
      this.filterSpecialist.splice(index, 1)
    },
    handleChangeFilterSpecialist(data) {
      if (typeof data === 'object') {
        this.filterSpecialist = data
        return
      }

      const isAlreadySelected = this.filterSpecialist.findIndex((v) => v === data)
      if (isAlreadySelected > -1) {
        this.handleDeleteFilterSpecialistByIndex(isAlreadySelected)
        return
      }
      this.filterSpecialist.unshift(data)
    },
    async onSubmitNutritionist(nutritionistId) {
      this.isLoadingNutritionists = true
      let programId = this.programId
      await this.$store.dispatch('admClients/updateProgramNutritionist', {
        programId,
        nutritionistId,
      })
      this.isLoadingNutritionists = false
      await this.$router.replace({
        name: 'admin.clients-programs',
        params: { clientId: this.clientId, programId: this.programId },
      })
      this.$toast({
        status: 'success',
        title: 'Success',
        description: 'Changing nutritionist',
        position: 'bottom-right',
        duration: 3000,
      })
    },
    getProfilePhoto(item) {
      return item.photoUrl ?? 'https://ik.imagekit.io/dietela/pwa_webp/profile/profile_neutral.webp'
    },
  },
}
</script>
